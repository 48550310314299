import logo from './images/logo.png'
import { IoLogoInstagram } from "react-icons/io";
import { BiLogoTelegram } from "react-icons/bi";
import { FaTiktok, FaYoutube, FaLocationArrow } from "react-icons/fa";
import { BsStars } from "react-icons/bs";
import { PiTelegramLogoLight } from "react-icons/pi";
import { TbPhoneCall } from "react-icons/tb";
import { CiLocationOn } from "react-icons/ci";
import { FaUserTie } from "react-icons/fa";
import { Analytics } from "@vercel/analytics/react"



function App() {
  return (
    <div className="mainPage">
      <div className="topDiv">
        <div className="topDiv_img">
          <img src={logo} alt="logotip" />
        </div>
        <h1>Auto <br /> <span>Witson</span></h1>
        <div className="topDiv_socialNetwork">
          <h1>IJTIMOIY TARMOQ <br /> <span>‌SAHIFALARIMIZ</span></h1>
          <div className="topdiv_social_Network_btn">
            <a href='https://instagram.com/auto_witson.uz/'><IoLogoInstagram /></a>
            <a href='https://t.me/Auto_WitsonUz'><BiLogoTelegram /></a>
            <a href='https://www.tiktok.com/@auto.witson.uz'><FaTiktok /></a>
            <a href='https://youtube.com/@autowitsonuz'><FaYoutube /></a>
          </div>
          <div>
            <h1>  <br /> <span>Shaxsiy Blog</span></h1>
          </div>
          <div className='lastDiv_location'>
            <a href="https://www.instagram.com/odilov_nematilla"><FaUserTie /><span>Shaxsiy blog</span></a>
          </div>
        </div>
        <div className="topDiv_Collection">
          <p>Xizmatlar</p>
          <ul>
            <li className='topDiv_Collection'><BsStars />Tanirovka</li>
            <li className='topDiv_Collection'><BsStars />Rangli Qoplamalar</li>
            <li className='topDiv_Collection'><BsStars />Akvaprint</li>
            <li className='topDiv_Collection'><BsStars />Branirovka Kuzov</li>
            <li className='topDiv_Collection'><BsStars />Chexol Polik</li>
            <li className='topDiv_Collection'><BsStars />Shumo Izolyatsiya</li>
            <li className='topDiv_Collection'><BsStars />Keramika</li>
          </ul>
        </div>
        <a className='admin_collection_btn' href="https://t.me/odilov_nematilla"><PiTelegramLogoLight /><span>Admin</span></a>
        <div className="lastDiv_headerText">
          <h1>Auto <br /> <span>Witson</span></h1>
          <p><span>Orzuyingizdagi</span> mashinalarni <br />
            ‌haqiqatga aylantiradi</p>
        </div>
        <div className="lastDiv_middleText">
          <h1> FILIALLARIMIZ <br /> <span>Malika Filliali</span></h1>
        </div>
        <div className='lastDiv_location'>
          <a href="https://yandex.com/maps/-/CDfurJ4~"><FaLocationArrow /><span>Yandeks Navigator</span></a>
          <a href="https://maps.app.goo.gl/ZMMvk9R8odbfWGjY7"><CiLocationOn /><span>Google Maps</span></a>
        </div>
        <div className="lastDiv_middleText">
          <h1>  <br /> <span>LABZAK  Filliali</span></h1>
        </div>
        <div className='lastDiv_location'>
          <a href="https://yandex.com/maps/-/CDfuv8My"><FaLocationArrow /><span>Yandeks Navigator</span></a>
          <a href="https://maps.app.goo.gl/xYwiJN5jLt2dAWom7"><CiLocationOn /><span>Google Maps</span></a>
        </div>
        <div className="lastDiv_middleText">
          <h1>  <br /> <span>Sebzor RIVIERA  Filliali</span></h1>
        </div>
        <div className='lastDiv_location'>
          <a href="https://yandex.com/maps/-/CDfuzQ0i"><FaLocationArrow /><span>Yandeks Navigator</span></a>
          <a href="https://maps.app.goo.gl/rAPCE2cutnLxLEq36"><CiLocationOn /><span>Google Maps</span></a>
        </div>
        <div className="lastDiv_middleText">
          <h1>  <br /> <span>Yunusobod Witson xxx Filliali</span></h1>
        </div>
        <div className='lastDiv_location'>
          <a href="https://yandex.uz/maps/-/CDfJNFIx"><FaLocationArrow /><span>Yandeks Navigator</span></a>
          <a href="https://maps.app.goo.gl/oo4y3nuBCvdNrGGL8"><CiLocationOn /><span>Google Maps</span></a>
        </div>
        <div className="last_icon_btn">
          <TbPhoneCall />
          <a href="tel:+998944242222">+998944242222</a>
        </div>
      </div>
      <Analytics />

    </div >
  );
}

export default App;
